





















































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import {JsonApiFilters, SubmissionResource, SurveyResource} from '@/interfaces';
import ButtonEditAction from '@/components/ui/ButtonEditAction.vue';
import DateUserCell from '@/components/ui/DateUserCell.vue';
import {surveyModule} from '@/store/modules';
import SurveyStatusChip from '@/components/ui/SurveyStatusChip.vue';
import SurveyType from '@/components/ui/SurveyType.vue';
import {Route, SurveyType as SurveyTypeEnum} from '@/enums';
import {Prop} from 'vue-property-decorator';
import ButtonSurveyMore from '@/components/surveys-edit/ButtonSurveyMore.vue';

@Component({
  components: {
    ButtonSurveyMore,
    SurveyType,
    SurveyStatusChip,
    DateUserCell,
    ButtonEditAction,
  },
})
export default class SurveyList extends Vue {
  @Prop({default: () => ({})}) private filters!: JsonApiFilters;
  @Prop({default: false}) private showFilters!: boolean;
  private search = '';
  private headers = [
    {
      text: 'Name',
      align: 'start',
      value: 'attributes.name',
    },
    {text: 'User Group', value: 'attributes.userGroup.label'},
    {text: 'Type', value: 'attributes.type'},
    {text: 'Status', value: 'attributes.published'},
    {text: 'Created', value: 'created'},
    {text: 'Modified', value: 'modified'},
    {text: 'Actions', value: 'attributes.actions'},
  ];
  private nonIntegratedOpen = '1';
  private nonIntegratedTargeted = true;
  private integratedOpen = true;
  private integratedTargeted = true;
  private template = false;

  private get types(): string {
    const types = [];
    if (this.nonIntegratedOpen) {
      types.push(SurveyTypeEnum.NonIntegratedOpen.toString());
    }
    if (this.nonIntegratedTargeted) {
      types.push(SurveyTypeEnum.NonIntegratedTargeted.toString());
    }
    if (this.integratedOpen) {
      types.push(SurveyTypeEnum.IntegratedOpen.toString());
    }
    if (this.integratedTargeted) {
      types.push(SurveyTypeEnum.IntegratedTargeted.toString());
    }
    return types.join(',');
  }

  get loading(): boolean {
    return surveyModule.listing;
  }

  get surveys(): SurveyResource[] {
    let surveys = surveyModule.list;
    if (this.filters.userGroupIds) {
      surveys = surveys.filter(survey =>
        this.filters.userGroupIds
          .split(',')
          .includes(String(survey.attributes.userGroupId))
      );
    }
    // if the published filter is set
    if (this.filters.published) {
      // if the survey's published status matches the published filter
      surveys = surveys.filter(
        survey =>
          survey.attributes.published === Boolean(this.filters.published)
      );
    }

    // if expired filter is set and filter is set to show expired surveys
    if (this.filters.expired && this.filters.expired === '1') {
      // if the survey has an expiry date and the survey's expiry date is less than now
      surveys = surveys.filter(
        survey =>
          survey.attributes.expires && survey.attributes.expires < new Date()
      );
    } else if (this.filters.expired && this.filters.expired === '0') {
      // only show surveys that have not expired
      // if the survey expiry is not set show it
      // if the survey expiry date has not passed, show it
      surveys = surveys.filter(
        survey =>
          !survey.attributes.expires || survey.attributes.expires >= new Date()
      );
    }
    return surveys;
  }

  async created(): Promise<void> {
    await this.refresh();
  }

  async refresh(): Promise<void> {
    // if no types are selected then it is not
    if (this.types === '') {
      surveyModule.reset();
      return;
    }

    this.filters.types = this.types;
    this.filters.template = this.template ? '1' : '0';

    surveyModule.reset();
    await surveyModule.loadMulti({filters: this.filters, append: false});
  }

  async view(item: SubmissionResource): Promise<void> {
    await this.$router.push({
      name: Route.SurveysEdit,
      params: {id: item.id},
    });
  }
}
